import { default as __nuxt_component_0 } from "/opt/build/repo/components/Modals/Bridge/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Modals/Bridge/Confirm/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Modals/Bridge/Completed.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { BridgingNetwork } from "@injectivelabs/sdk-ui-ts";
import { injToken } from "@/app/data/token";
import { getBridgingNetworkBySymbol } from "@/app/data/bridge";
import {
  Modal,
  BridgeType,
  BridgeField,
  BridgeBusEvents,
  TransferDirection
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Bridge",
  setup(__props) {
    const bankStore = useBankStore();
    const modalStore = useModalStore();
    const peggyStore = usePeggyStore();
    const walletStore = useWalletStore();
    const { resetForm: resetBridgeForm, values: formValues } = useForm({
      initialValues: {
        [BridgeField.BridgingNetwork]: BridgingNetwork.Ethereum,
        [BridgeField.TransferDirection]: TransferDirection.bankToTradingAccount,
        [BridgeField.BridgeType]: BridgeType.Transfer,
        [BridgeField.Token]: injToken,
        [BridgeField.Denom]: injToken.denom,
        [BridgeField.Amount]: "",
        [BridgeField.Memo]: "",
        [BridgeField.Destination]: ""
      },
      keepValuesOnUnmount: true
    });
    onMounted(() => {
      handlePreFillCosmosWallet();
      useEventBus(BridgeBusEvents.Deposit).on(handleDeposit);
      useEventBus(BridgeBusEvents.Withdraw).on(handleWithdraw);
      useEventBus(BridgeBusEvents.Transfer).on(handleTransfer);
      useEventBus(BridgeBusEvents.TransferToBank).on(
        handleTransferToBank
      );
    });
    function handlePreFillCosmosWallet() {
      if (walletStore.isCosmosWallet) {
        formValues[BridgeField.BridgingNetwork] = BridgingNetwork.CosmosHub;
      }
    }
    function handleBridgeInit() {
      nextTick(() => {
        modalStore.closeModal(Modal.Bridge);
        modalStore.openModal({ type: Modal.BridgeConfirm });
      });
    }
    function resetForm(token) {
      resetBridgeForm();
      formValues[BridgeField.BridgeType] = BridgeType.Transfer;
      if (token) {
        formValues[BridgeField.Token] = token;
        formValues[BridgeField.Denom] = token.denom;
      }
    }
    function handleBridgeConfirmed() {
      resetForm();
      modalStore.closeModal(Modal.BridgeConfirm);
      modalStore.openModal({ type: Modal.BridgeCompleted });
    }
    function handleTransfer(token = injToken) {
      resetForm(token);
      formValues[BridgeField.BridgeType] = BridgeType.Transfer;
      formValues[BridgeField.TransferDirection] = TransferDirection.bankToTradingAccount;
      if (!bankStore.hasEnoughInjForGas) {
        return modalStore.openModal({ type: Modal.InsufficientInjForGas });
      }
      modalStore.openModal({ type: Modal.Bridge });
    }
    function handleTransferToBank(token = injToken) {
      resetForm(token);
      formValues[BridgeField.BridgeType] = BridgeType.Transfer;
      formValues[BridgeField.TransferDirection] = TransferDirection.tradingAccountToBank;
      modalStore.openModal({ type: Modal.Bridge });
    }
    function handleDeposit(token = injToken) {
      resetForm(token);
      formValues[BridgeField.BridgeType] = BridgeType.Deposit;
      formValues[BridgeField.BridgingNetwork] = getBridgingNetworkBySymbol(
        token.symbol
      );
      formValues[BridgeField.TransferDirection] = TransferDirection.tradingAccountToBank;
      peggyStore.updateErc20BalancesWithTokenAndPrice();
      modalStore.openModal({ type: Modal.Bridge });
    }
    function handleWithdraw(token = injToken) {
      resetForm(token);
      const bridgingNetworkValue = getBridgingNetworkBySymbol(token.symbol);
      formValues[BridgeField.BridgeType] = BridgeType.Withdraw;
      formValues[BridgeField.BridgingNetwork] = bridgingNetworkValue;
      formValues[BridgeField.TransferDirection] = TransferDirection.tradingAccountToBank;
      modalStore.openModal({ type: Modal.Bridge });
    }
    return (_ctx, _cache) => {
      const _component_ModalsBridge = __nuxt_component_0;
      const _component_ModalsBridgeConfirm = __nuxt_component_1;
      const _component_ModalsBridgeCompleted = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_ModalsBridge, { "onBridge:init": handleBridgeInit }),
        _createVNode(_component_ModalsBridgeConfirm, { "onForm:submit": handleBridgeConfirmed }),
        _createVNode(_component_ModalsBridgeCompleted)
      ]);
    };
  }
});

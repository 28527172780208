import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Modals/Bridge/TransferDirectionSwitch.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Modals/Bridge/NetworkSelect.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Input/Index.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/App/Checkbox.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Modals/Bridge/NotSupportedBridgeTypeNote.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/App/Select/Token/Index.vue";
import { default as __nuxt_component_8 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_9 } from "/opt/build/repo/components/Common/Allowance.vue";
import { default as __nuxt_component_10 } from "/opt/build/repo/components/Common/UserNotConnectedNote.vue";
import { default as __nuxt_component_11 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, mergeProps as _mergeProps, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "flex items-center" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { key: 2 };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "mb-4" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = { key: 0 };
const _hoisted_10 = { class: "mt-6" };
const _hoisted_11 = {
  key: 0,
  class: "text-red-500 text-xs mt-1"
};
const _hoisted_12 = { class: "my-4 w-full" };
const _hoisted_13 = { class: "flex items-center justify-between text-gray-200" };
const _hoisted_14 = { class: "text-xs flex items-center" };
const _hoisted_15 = {
  key: 0,
  class: "mt-2"
};
const _hoisted_16 = { key: 2 };
const _hoisted_17 = { class: "mt-6 text-center" };
const _hoisted_18 = { key: 0 };
const _hoisted_19 = { key: 1 };
const _hoisted_20 = { key: 2 };
import {
  Status,
  StatusType,
  BigNumberInBase,
  BigNumberInWei,
  INJ_DENOM
} from "@injectivelabs/utils";
import {
  BINANCE_DEPOSIT_ADDRESSES,
  INJ_GAS_BUFFER_FOR_BRIDGE,
  UI_DEFAULT_DISPLAY_DECIMALS
} from "@/app/utils/constants";
import {
  Modal,
  BridgeType,
  BridgeField,
  TransferDirection
} from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  emits: ["bridge:init", "form:reset"],
  setup(__props, { emit }) {
    const modalStore = useModalStore();
    const peggyStore = usePeggyStore();
    const walletStore = useWalletStore();
    const { $onError } = useNuxtApp();
    const resetForm = useResetForm();
    const formErrors = useFormErrors();
    const formValues = useFormValues();
    const {
      isDeposit,
      isWithdraw,
      originIsEthereum,
      networkIsNotSupported,
      destinationIsInjective
    } = useBridgeState({
      formValues
    });
    const { transferableBalancesWithToken } = useBridgeBalance({
      formValues
    });
    const memoRequired = ref(false);
    const status = reactive(new Status(StatusType.Loading));
    const isModalOpen = computed(() => modalStore.modals[Modal.Bridge]);
    const hasFormErrors = computed(() => Object.keys(formErrors.value).length > 0);
    const shouldConnectMetamask = computed(
      () => walletStore.isCosmosWallet && isDeposit.value && originIsEthereum.value
    );
    const maxDecimals = computed(() => {
      const defaultDecimalsLessThanTokenDecimals = UI_DEFAULT_DISPLAY_DECIMALS < formValues.value[BridgeField.Token].decimals;
      if (defaultDecimalsLessThanTokenDecimals) {
        return UI_DEFAULT_DISPLAY_DECIMALS;
      }
      return formValues.value[BridgeField.Token].decimals;
    });
    const balanceWithToken = computed(() => {
      const balanceWithToken2 = transferableBalancesWithToken.value.find(
        (b) => b.token.denom === denom.value
      );
      if (!balanceWithToken2) {
        return;
      }
      if (balanceWithToken2.denom !== INJ_DENOM) {
        return balanceWithToken2;
      }
      const noGasBufferNeededForTransfer = walletStore.isWalletExemptFromGasFee || formValues.value[BridgeField.TransferDirection] === TransferDirection.tradingAccountToBank;
      if (noGasBufferNeededForTransfer) {
        return balanceWithToken2;
      }
      const transferableBalance = new BigNumberInWei(balanceWithToken2.balance).toBase().minus(INJ_GAS_BUFFER_FOR_BRIDGE);
      const transferableBalanceCapped = new BigNumberInWei(
        transferableBalance.gt(0) ? transferableBalance : 0
      );
      return {
        ...balanceWithToken2,
        balance: transferableBalanceCapped.toFixed()
      };
    });
    const needsAllowanceSet = computed(() => {
      var _a;
      const balanceWithTokenAndAllowance = peggyStore.tradeableErc20BalancesWithTokenAndPrice.find(
        (token) => token.denom === denom.value
      );
      const allowance = new BigNumberInBase(
        ((_a = balanceWithTokenAndAllowance == null ? void 0 : balanceWithTokenAndAllowance.erc20Balance) == null ? void 0 : _a.allowance) || 0
      );
      return isDeposit.value && originIsEthereum.value && allowance.lte(0);
    });
    const { value: denom } = useStringField({
      name: BridgeField.Denom,
      initialValue: formValues.value[BridgeField.Denom]
    });
    const { value: destination, errors: destinationErrors } = useStringField({
      name: BridgeField.Destination,
      rule: "",
      dynamicRule: computed(
        () => isWithdraw.value && destinationIsInjective.value ? "required|injAddress" : ""
      )
    });
    const { value: memo, resetField: resetMemo } = useStringField({
      name: BridgeField.Memo,
      rule: "",
      dynamicRule: computed(() => {
        return memoRequired.value ? "required" : "";
      })
    });
    function handleAmountChange({ amount }) {
      formValues.value[BridgeField.Amount] = amount;
    }
    function handleTokenChange() {
      nextTick(() => {
        if (balanceWithToken.value) {
          formValues.value[BridgeField.Amount] = "";
          formValues.value[BridgeField.Token] = balanceWithToken.value.token;
        }
      });
    }
    function handleBridgeInit() {
      emit("bridge:init");
    }
    function handleModalClose() {
      resetForm();
      modalStore.closeModal(Modal.Bridge);
    }
    watch(isModalOpen, (modalShown) => {
      if (modalShown) {
        status.setLoading();
        Promise.all([peggyStore.fetchErc20BalancesWithTokenAndPrice()]).catch($onError).finally(() => status.setIdle());
      }
    });
    watch(destination, (value) => {
      if (BINANCE_DEPOSIT_ADDRESSES.includes(value)) {
        memoRequired.value = true;
      } else {
        formValues.value[BridgeField.Memo] = "";
        memoRequired.value = false;
      }
    });
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_ModalsBridgeTransferDirectionSwitch = __nuxt_component_1;
      const _component_ModalsBridgeNetworkSelect = __nuxt_component_2;
      const _component_AppInput = __nuxt_component_3;
      const _component_BaseIcon = __nuxt_component_4;
      const _component_AppCheckbox = __nuxt_component_5;
      const _component_ModalsBridgeNotSupportedBridgeTypeNote = __nuxt_component_6;
      const _component_AppSelectToken = __nuxt_component_7;
      const _component_AppButton = __nuxt_component_8;
      const _component_CommonAllowance = __nuxt_component_9;
      const _component_CommonUserNotConnectedNote = __nuxt_component_10;
      const _component_AppModal = __nuxt_component_11;
      return _openBlock(), _createBlock(_component_AppModal, {
        sm: "",
        show: _unref(isModalOpen),
        ignore: [".v-popper__popper"],
        "show-loading": _unref(status).isLoading(),
        "modal-closed:animation": _unref(resetForm),
        "onModal:closed": handleModalClose
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, [
            _unref(formValues)[_unref(BridgeField).BridgeType] === _unref(BridgeType).Deposit ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("bridge.depositToInjective")), 1)) : _unref(formValues)[_unref(BridgeField).BridgeType] === _unref(BridgeType).Withdraw ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("bridge.withdrawFromInjective")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("bridge.transferFromToTradingAccount")), 1)),
            _unref(formValues)[_unref(BridgeField).BridgeType] === _unref(BridgeType).Transfer ? (_openBlock(), _createBlock(_component_CommonInfoTooltip, {
              key: 3,
              class: "ml-2",
              tooltip: _ctx.$t("bridge.transferTitleTooltip")
            }, null, 8, ["tooltip"])) : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _unref(formValues)[_unref(BridgeField).BridgeType] === _unref(BridgeType).Transfer ? (_openBlock(), _createBlock(_component_ModalsBridgeTransferDirectionSwitch, { key: 0 })) : (_openBlock(), _createBlock(_component_ModalsBridgeNetworkSelect, { key: 1 }, {
                title: _withCtx(() => [
                  _unref(formValues)[_unref(BridgeField).BridgeType] === _unref(BridgeType).Deposit ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("bridge.selectOriginNetwork")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("bridge.selectDestinationNetwork")), 1))
                ]),
                _: 1
              }))
            ]),
            _unref(isWithdraw) && _unref(destinationIsInjective) ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_AppInput, {
                  modelValue: _unref(destination),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(destination) ? destination.value = $event : null),
                  "clear-on-paste": "",
                  label: _ctx.$t("bridge.injAddress"),
                  placeholder: "inj",
                  "wrapper-classes": "py-2 px-1",
                  "data-cy": "transfer-modal-inj-address-input"
                }, null, 8, ["modelValue", "label"]),
                _unref(destinationErrors).length > 0 ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_unref(destinationErrors)[0]), 1)) : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_CommonInfoTooltip, {
                    tooltip: _ctx.$t("memo.memoTooltip")
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_14, [
                        _createTextVNode(_toDisplayString(_ctx.$t("memo.memo")) + " ", 1),
                        _createVNode(_component_BaseIcon, {
                          name: "circle-info",
                          class: "text-gray-500 w-3 h-3 ml-2"
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["tooltip"]),
                  _createVNode(_component_AppCheckbox, {
                    modelValue: _unref(memoRequired),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => _isRef(memoRequired) ? memoRequired.value = $event : null),
                    onInput: _unref(resetMemo)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("common.required")), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onInput"])
                ]),
                _unref(memoRequired) ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode(_component_AppInput, {
                    modelValue: _unref(memo),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => _isRef(memo) ? memo.value = $event : null),
                    "wrapper-classes": "py-2 px-1",
                    placeholder: _ctx.$t("memo.memoPlaceholder")
                  }, null, 8, ["modelValue", "placeholder"])
                ])) : _createCommentVNode("", true)
              ])
            ])) : _createCommentVNode("", true),
            _unref(networkIsNotSupported) ? (_openBlock(), _createBlock(_component_ModalsBridgeNotSupportedBridgeTypeNote, _normalizeProps(_mergeProps({ key: 1 }, {
              selectedNetwork: _unref(formValues)[_unref(BridgeField).BridgingNetwork]
            })), null, 16)) : (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", null, [
                _createVNode(_component_AppSelectToken, _mergeProps({
                  denom: _unref(denom),
                  "onUpdate:denom": _cache[3] || (_cache[3] = ($event) => _isRef(denom) ? denom.value = $event : null)
                }, {
                  maxDecimals: _unref(maxDecimals),
                  required: true,
                  inputDisabled: _unref(needsAllowanceSet),
                  amountFieldName: _unref(BridgeField).Amount,
                  options: _unref(transferableBalancesWithToken)
                }, {
                  "onUpdate:denom": handleTokenChange,
                  "onUpdate:max": handleAmountChange
                }), {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("bridge.amount")), 1)
                  ]),
                  _: 1
                }, 16, ["denom"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _unref(shouldConnectMetamask) ? (_openBlock(), _createBlock(_component_AppButton, {
                  key: 0,
                  lg: "",
                  class: "w-full font-semibold rounded bg-blue-500 text-blue-900",
                  "data-cy": "transfer-modal-transfer-now-button",
                  disabled: true,
                  onClick: _cache[4] || (_cache[4] = () => {
                  })
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("bridge.keplrConnectedForEthereum")), 1)
                  ]),
                  _: 1
                })) : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _unref(needsAllowanceSet) && _unref(balanceWithToken) ? (_openBlock(), _createBlock(_component_CommonAllowance, _normalizeProps(_mergeProps({ key: 0 }, {
                    balanceWithToken: _unref(balanceWithToken)
                  })), null, 16)) : (_openBlock(), _createBlock(_component_AppButton, {
                    key: 1,
                    lg: "",
                    disabled: _unref(hasFormErrors) || _unref(formValues)[_unref(BridgeField).Amount] === "",
                    class: "w-full font-semibold rounded bg-blue-500 text-blue-900",
                    "data-cy": "transfer-modal-transfer-now-button",
                    onClick: handleBridgeInit
                  }, {
                    default: _withCtx(() => [
                      _unref(formValues)[_unref(BridgeField).BridgeType] === _unref(BridgeType).Deposit ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t("bridge.depositNow")), 1)) : _unref(formValues)[_unref(BridgeField).BridgeType] === _unref(BridgeType).Withdraw ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t("bridge.withdrawNow")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.$t("bridge.transferNow")), 1))
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                ], 64))
              ])
            ]))
          ])) : (_openBlock(), _createBlock(_component_CommonUserNotConnectedNote, { key: 1 }))
        ]),
        _: 1
      }, 8, ["show", "ignore", "show-loading", "modal-closed:animation"]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full" };
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "Allowance",
  props: {
    balanceWithToken: {
      type: Object,
      required: true
    }
  },
  emits: ["allowance:set"],
  setup(__props, { emit }) {
    const props = __props;
    const peggyStore = usePeggyStore();
    const { $onError } = useNuxtApp();
    const { success } = useNotifications();
    const { t } = useLang();
    const status = reactive(new Status(StatusType.Idle));
    function handleSetAllowance() {
      status.setLoading();
      peggyStore.setTokenAllowance(props.balanceWithToken).then(() => {
        emit("allowance:set");
        success({
          title: t("bridge.successfullySetAllowance")
        });
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_AppButton = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppButton, {
          lg: "",
          status: _unref(status),
          class: "w-full bg-blue-500 text-blue-900 font-semibold",
          "data-cy": "allowance-modal-set-button",
          onClick: handleSetAllowance
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("bridge.setAllowance")), 1)
          ]),
          _: 1
        }, 8, ["status"])
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Modals/Bridge/NotSupportedBridgeTypeNote.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/UserNotConnectedNote.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 2 };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "text-xl font-semibold mt-6" };
const _hoisted_7 = { class: "mt-4 text-gray-300" };
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { key: 1 };
const _hoisted_10 = { class: "flex items-center justify-between mt-6 gap-4" };
const _hoisted_11 = { class: "text-primary-500 cursor-pointer w-full" };
const _hoisted_12 = { class: "flex items-center justify-center gap-1" };
const _hoisted_13 = { class: "flex items-center justify-center gap-1" };
const _hoisted_14 = { "data-cy": "transfer-completed-hub-track-link" };
import { getExplorerUrl } from "@injectivelabs/sdk-ui-ts";
import { NETWORK } from "@/app/utils/constants";
import { getHubUrl } from "@/app/utils/helpers";
import { Modal, BridgeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Completed",
  setup(__props) {
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const hubUrl = `${getHubUrl()}/bridge`;
    const formValues = useFormValues();
    const resetForm = useResetForm();
    const {
      isDeposit,
      isTransfer,
      isWithdraw,
      destinationIsInjective,
      networkIsNotSupported
    } = useBridgeState({
      formValues
    });
    const explorerUrl = computed(
      () => `${getExplorerUrl(NETWORK)}/account/${walletStore.injectiveAddress}/`
    );
    const isModalOpen = computed(() => modalStore.modals[Modal.BridgeCompleted]);
    const isOnChainTransaction = computed(
      () => isTransfer.value || isWithdraw.value && destinationIsInjective.value
    );
    function handleModalClose() {
      resetForm();
      modalStore.closeModal(Modal.BridgeCompleted);
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppButton = __nuxt_component_2;
      const _component_ModalsBridgeNotSupportedBridgeTypeNote = __nuxt_component_3;
      const _component_CommonUserNotConnectedNote = __nuxt_component_4;
      const _component_AppModal = __nuxt_component_5;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "data-cy": "transfer-completed-modal",
        "onModal:closed": handleModalClose
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, [
            _unref(isDeposit) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("bridge.depositToInjective")), 1)) : _unref(isWithdraw) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("bridge.withdrawFromInjective")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("bridge.transferFromToTradingAccount")), 1))
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              !_unref(networkIsNotSupported) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("bridge.transactionConfirmed")), 1),
                _createElementVNode("p", _hoisted_7, [
                  _unref(isTransfer) ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("bridge.defaultNote")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("bridge.withdrawFromEthereumNote")), 1))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _unref(isOnChainTransaction) ? (_openBlock(), _createBlock(_component_NuxtLink, {
                      key: 0,
                      to: _unref(explorerUrl),
                      target: "_blank",
                      "data-cy": "transfer-completed-modal-explorer-link"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("bridge.seeOnExplorer")), 1),
                          _createVNode(_component_BaseIcon, {
                            name: "external-link",
                            class: "w-3 h-3"
                          })
                        ])
                      ]),
                      _: 1
                    }, 8, ["to"])) : (_openBlock(), _createBlock(_component_NuxtLink, {
                      key: 1,
                      to: hubUrl,
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("bridge.trackTransaction")), 1),
                          _createVNode(_component_BaseIcon, {
                            name: "external-link",
                            class: "w-3 h-3"
                          })
                        ])
                      ]),
                      _: 1
                    }))
                  ]),
                  _createVNode(_component_AppButton, {
                    class: "w-full font-semibold rounded bg-blue-500 text-blue-900",
                    "data-cy": "transfer-completed-modal-ok-button",
                    lg: "",
                    onClick: handleModalClose
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("common.ok")), 1)
                    ]),
                    _: 1
                  })
                ])
              ])) : (_openBlock(), _createBlock(_component_ModalsBridgeNotSupportedBridgeTypeNote, _normalizeProps(_mergeProps({ key: 1 }, {
                selectedNetwork: _unref(formValues)[_unref(BridgeField).BridgingNetwork]
              })), null, 16))
            ])) : (_openBlock(), _createBlock(_component_CommonUserNotConnectedNote, { key: 1 }))
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Modals/Bridge/NetworkSelectOption.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Select/Field/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "text-lg text-gray-200 mb-4" };
import { BridgingNetwork } from "@injectivelabs/sdk-ui-ts";
import { networksMeta } from "@/app/data/bridge";
import { BridgeField } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "NetworkSelect",
  setup(__props) {
    const formValues = useFormValues();
    const { isWithdraw } = useBridgeState({
      formValues
    });
    const { value: network } = useStringField({
      name: BridgeField.BridgingNetwork
    });
    const options = computed(() => {
      return networksMeta.filter((option) => {
        if (isWithdraw.value) {
          return true;
        }
        return option.value !== BridgingNetwork.Injective;
      }).map((option) => {
        return {
          display: option.text,
          value: option.value,
          icon: option.icon
        };
      });
    });
    return (_ctx, _cache) => {
      const _component_ModalsBridgeNetworkSelectOption = __nuxt_component_0;
      const _component_AppSelectField = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title")
        ]),
        _createVNode(_component_AppSelectField, {
          modelValue: _unref(network),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(network) ? network.value = $event : null),
          "selected-class": "h-20 bg-gray-1000",
          options: _unref(options),
          placeholder: _ctx.$t("connect.selectDerivationPath")
        }, {
          "selected-option": _withCtx(({ option }) => [
            option ? (_openBlock(), _createBlock(_component_ModalsBridgeNetworkSelectOption, {
              key: 0,
              selected: "",
              option
            }, null, 8, ["option"])) : _createCommentVNode("", true)
          ]),
          option: _withCtx(({ option, active }) => [
            _createVNode(_component_ModalsBridgeNetworkSelectOption, {
              option,
              active
            }, null, 8, ["option", "active"])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "placeholder"])
      ]);
    };
  }
});

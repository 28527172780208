"use strict";
import {
  BridgingNetwork
} from "@injectivelabs/sdk-ui-ts";
import { BridgeType, BridgeField } from "@/types";
export function useBridgeBalance({
  formValues
}) {
  const tokenStore = useTokenStore();
  const peggyStore = usePeggyStore();
  const bankStore = useBankStore();
  const bankBalancesWithToken = computed(() => {
    return bankStore.bankBalances.map((bankBalance) => {
      const token = tokenStore.tradeableTokens.find(
        (token2) => token2.denom.toLowerCase() === bankBalance.denom.toLowerCase()
      );
      return {
        token,
        denom: bankBalance.denom,
        balance: bankBalance.amount,
        usdPrice: tokenStore.tokenUsdPrice((token == null ? void 0 : token.coinGeckoId) || "")
      };
    }).filter(
      (balanceWithToken) => balanceWithToken.token
    );
  });
  const erc20Balances = computed(
    () => peggyStore.tradeableErc20BalancesWithTokenAndPrice.map((balance) => {
      return {
        ...balance,
        balance: balance.erc20Balance.balance
      };
    })
  );
  const transferableBalancesWithToken = computed(() => {
    if (formValues.value[BridgeField.BridgeType] === BridgeType.Deposit) {
      return erc20Balances.value;
    }
    const destinationIsEthereum = formValues.value[BridgeField.BridgingNetwork] === BridgingNetwork.Ethereum;
    if (destinationIsEthereum) {
      return bankBalancesWithToken.value.filter(
        (balance) => {
          var _a;
          return (_a = balance.token.erc20) == null ? void 0 : _a.address;
        }
      );
    }
    return bankBalancesWithToken.value;
  });
  return {
    transferableBalancesWithToken
  };
}

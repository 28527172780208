import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "mt-4",
  "data-cy": "ibc-transfer-modal-note-text-content"
};
const _hoisted_2 = { class: "flex justify-start items-center" };
const _hoisted_3 = { class: "text-xs text-orange-500 ml-2 flex items-center gap-2" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { key: 2 };
const _hoisted_7 = { class: "text-center mt-6" };
const _hoisted_8 = { class: "flex items-center justify-center" };
const _hoisted_9 = /* @__PURE__ */ _createElementVNode("span", { class: "mr-2" }, "Injective Hub", -1);
import { BridgingNetwork, CosmosNetworks } from "@injectivelabs/sdk-ui-ts";
import { getHubUrl } from "@/app/utils/helpers";
export default /* @__PURE__ */ _defineComponent({
  __name: "NotSupportedBridgeTypeNote",
  props: {
    selectedNetwork: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const bridgeUrl = `${getHubUrl()}/bridge`;
    const isWormholeTransfer = computed(
      () => [BridgingNetwork.Solana].includes(props.selectedNetwork) || [BridgingNetwork.EthereumWh].includes(props.selectedNetwork)
    );
    const isIbcTransfer = computed(
      () => CosmosNetworks.includes(props.selectedNetwork)
    );
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      const _component_NuxtLink = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createVNode(_component_BaseIcon, {
              name: "circle-info",
              md: ""
            }),
            _unref(isWormholeTransfer) ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("bridge.transfersNote", { network: _ctx.$t("bridge.wormhole") })), 1)) : _unref(isIbcTransfer) ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("bridge.transfersNote", { network: _ctx.$t("bridge.ibc") })), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("bridge.transfersNote", { network: _ctx.$t("common.network") })), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_NuxtLink, {
            to: bridgeUrl,
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppButton, {
                lg: "",
                class: "w-full bg-blue-500 text-blue-900",
                "data-cy": "ibc-transfer-modal-hub-button"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _hoisted_9,
                    _createVNode(_component_BaseIcon, {
                      name: "external-link",
                      class: "w-3 h-3"
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]);
    };
  }
});

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "text-sm text-gray-500" }
const _hoisted_3 = { class: "flex flex-col-reverse xs:flex-row items-end xs:items-center justify-between" }
const _hoisted_4 = { class: "text-gray-500 text-sm" }
const _hoisted_5 = { class: "text-gray-200 xs:ml-2 text-sm" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        _renderSlot(_ctx.$slots, "amountInUsd")
      ]),
      _createElementVNode("span", _hoisted_5, [
        _renderSlot(_ctx.$slots, "amount")
      ])
    ])
  ]))
}
import { default as __nuxt_component_0 } from "/opt/build/repo/components/Modals/Bridge/NetworkCard.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex justify-between items-center" };
const _hoisted_2 = {
  class: "bg-blue-500 min-w-6 h-6 mx-6 flex items-center justify-center rounded-full cursor-pointer",
  "data-cy": "transfer-modal-direction-toggle-button"
};
import {
  BridgeField,
  TransferDirection,
  TransferSide
} from "@/types";
import { transferSideMeta } from "@/app/data/bridge";
export default /* @__PURE__ */ _defineComponent({
  __name: "TransferDirectionSwitch",
  setup(__props) {
    const formValues = useFormValues();
    const originNetworkMeta = computed(
      () => formValues.value[BridgeField.TransferDirection] === TransferDirection.bankToTradingAccount ? transferSideMeta[TransferSide.Bank] : transferSideMeta[TransferSide.TradingAccount]
    );
    const destinationNetworkMeta = computed(
      () => formValues.value[BridgeField.TransferDirection] === TransferDirection.bankToTradingAccount ? transferSideMeta[TransferSide.TradingAccount] : transferSideMeta[TransferSide.Bank]
    );
    function handleDirectionSwitch() {
      const updatedTransferDirection = formValues.value[BridgeField.TransferDirection] === TransferDirection.bankToTradingAccount ? TransferDirection.tradingAccountToBank : TransferDirection.bankToTradingAccount;
      formValues.value[BridgeField.TransferDirection] = updatedTransferDirection;
    }
    return (_ctx, _cache) => {
      const _component_ModalsBridgeNetworkCard = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModalsBridgeNetworkCard, {
          class: "w-1/2",
          "hide-icon": "",
          "data-cy": "transfer-modal-from-text-content",
          "network-meta": _unref(originNetworkMeta)
        }, null, 8, ["network-meta"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseIcon, {
            name: "arrow",
            swap: "",
            class: "text-gray-1000 w-6 h-6 rotate-180 select-none",
            onClick: handleDirectionSwitch
          })
        ]),
        _createVNode(_component_ModalsBridgeNetworkCard, {
          class: "w-1/2",
          "hide-icon": "",
          "data-cy": "transfer-modal-to-text-content",
          "network-meta": _unref(destinationNetworkMeta)
        }, null, 8, ["network-meta"])
      ]);
    };
  }
});

import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Modals/Bridge/NetworkCard.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Modals/Bridge/Confirm/Row.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Modals/Bridge/NotSupportedBridgeTypeNote.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Common/UserNotConnectedNote.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/App/Modal.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 2 };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = { class: "text-xl font-medium mt-6" };
const _hoisted_7 = {
  key: 0,
  class: "text-center my-8"
};
const _hoisted_8 = {
  class: "text-gray-200 text-2xl font-bold tracking-0.4 mt-4",
  "data-cy": "transfer-confirm-modal-value-text-content"
};
const _hoisted_9 = {
  key: 2,
  class: "text-gray-500 text-sm tracking-0.4 mt-2",
  "data-cy": "transfer-confirm-modal-value-usd-text-content"
};
const _hoisted_10 = {
  key: 1,
  class: "flex justify-between items-center mt-6"
};
const _hoisted_11 = { class: "bg-blue-500 min-w-6 h-6 mx-6 flex items-center justify-center rounded-full" };
const _hoisted_12 = {
  key: 2,
  class: "mt-6"
};
const _hoisted_13 = { "data-cy": "transfer-confirm-modal-amount-text-content" };
const _hoisted_14 = { "data-cy": "transfer-confirm-modal-amount-usd-text-content" };
const _hoisted_15 = { "data-cy": "transfer-confirm-modal-bridge-fee-text-content" };
const _hoisted_16 = { "data-cy": "transfer-confirm-modal-bridge-fee-usd-text-content" };
const _hoisted_17 = { key: 3 };
const _hoisted_18 = { "data-cy": "transfer-confirm-modal-transfer-amount-text-content" };
const _hoisted_19 = { "data-cy": "transfer-confirm-modal-transfer-amount-usd-text-content" };
const _hoisted_20 = { "data-cy": "transfer-confirm-modal-gas-fee-text-content" };
const _hoisted_21 = { "data-cy": "transfer-confirm-modal-gas-fee-usd-text-content" };
const _hoisted_22 = { "data-cy": "transfer-confirm-modal-gas-fee-text-content" };
const _hoisted_23 = { class: "text-center mt-6" };
const _hoisted_24 = { key: 0 };
const _hoisted_25 = { key: 1 };
const _hoisted_26 = { key: 2 };
import { BigNumberInBase, Status } from "@injectivelabs/utils";
import {
  ZERO_IN_BASE,
  BRIDGE_FEE_IN_USD
} from "@injectivelabs/sdk-ui-ts";
import { useI18n } from "vue-i18n";
import { Modal, BridgeField, BusEvents } from "@/types";
import { injToken } from "@/app/data/token";
import {
  INJ_GAS_FEE,
  UI_DEFAULT_DISPLAY_DECIMALS,
  UI_DEFAULT_MIN_DISPLAY_DECIMALS
} from "@/app/utils/constants";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  emits: ["form:submit"],
  setup(__props, { emit }) {
    const bankStore = useBankStore();
    const tokenStore = useTokenStore();
    const peggyStore = usePeggyStore();
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    const { t } = useI18n();
    const { success } = useNotifications();
    const { $onError } = useNuxtApp();
    const formValues = useFormValues();
    const resetForm = useResetForm();
    const {
      isDeposit,
      isTransfer,
      isWithdraw,
      originNetworkMeta,
      destinationNetworkMeta,
      destinationIsEthereum,
      destinationIsInjective,
      isBankToTradingAccount,
      networkIsNotSupported,
      originIsInjective
    } = useBridgeState({
      formValues
    });
    const { emit: emitFundingRefresh } = useEventBus(BusEvents.FundingRefresh);
    const gasFee = new BigNumberInBase(INJ_GAS_FEE);
    const gasFeeToString = gasFee.toFormat();
    const status = reactive(new Status());
    const isModalOpen = computed(() => modalStore.modals[Modal.BridgeConfirm]);
    const injTokenWithPrice = computed(() => ({
      ...injToken,
      usdPrice: tokenStore.injUsdPrice
    }));
    const balanceWithTokenAndPrice = computed(() => {
      return peggyStore.tradeableErc20BalancesWithTokenAndPrice.find(
        (token) => token.denom === formValues.value[BridgeField.Token].denom
      );
    });
    const usdPrice = computed(
      () => {
        var _a;
        return new BigNumberInBase(((_a = balanceWithTokenAndPrice.value) == null ? void 0 : _a.usdPrice) || 0);
      }
    );
    const amount = computed(
      () => new BigNumberInBase(formValues.value[BridgeField.Amount] || 0)
    );
    const { valueToString: amountToString } = useBigNumberFormatter(amount, {
      decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
    });
    const amountInUsd = computed(
      () => amount.value.multipliedBy(new BigNumberInBase(usdPrice.value))
    );
    const { valueToString: amountInUsdToString } = useBigNumberFormatter(
      amountInUsd,
      {
        decimalPlaces: UI_DEFAULT_MIN_DISPLAY_DECIMALS
      }
    );
    const ethBridgeFee = computed(() => {
      if (!balanceWithTokenAndPrice.value) {
        return ZERO_IN_BASE;
      }
      if (!balanceWithTokenAndPrice.value.usdPrice) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(BRIDGE_FEE_IN_USD).dividedBy(
        balanceWithTokenAndPrice.value.usdPrice
      );
    });
    const { valueToString: ethBridgeFeeToString } = useBigNumberFormatter(
      ethBridgeFee,
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const ethBridgeFeeInUsd = computed(
      () => ethBridgeFee.value.multipliedBy(new BigNumberInBase(usdPrice.value))
    );
    const { valueToString: ethBridgeFeeInUsdToString } = useBigNumberFormatter(ethBridgeFeeInUsd);
    const transferAmount = computed(() => {
      if (destinationIsEthereum.value) {
        return amount.value.minus(ethBridgeFee.value);
      }
      return amount.value;
    });
    const { valueToString: transferAmountToString } = useBigNumberFormatter(
      transferAmount,
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const isConfirmationDisabled = computed(() => {
      return transferAmount.value.lte(0) || originIsInjective && !bankStore.hasEnoughInjForGas;
    });
    const transferAmountInUsd = computed(
      () => transferAmount.value.multipliedBy(new BigNumberInBase(usdPrice.value))
    );
    const { valueToString: transferAmountInUsdToString } = useBigNumberFormatter(transferAmountInUsd);
    const gasFeeInUsd = computed(
      () => gasFee.multipliedBy(new BigNumberInBase(injTokenWithPrice.value.usdPrice))
    );
    const { valueToString: gasFeeInUsdToString } = useBigNumberFormatter(gasFeeInUsd);
    const handlerFunction = computed(() => {
      if (isTransfer.value) {
        return isBankToTradingAccount.value ? handleTransferToTradingAccount : handleTransferToBank;
      }
      if (isDeposit.value) {
        return handleDeposit;
      }
      if (isWithdraw.value && destinationIsInjective.value) {
        return handleWithdrawToInjective;
      }
      return handleWithdraw;
    });
    function handleModalClose() {
      resetForm();
      modalStore.closeModal(Modal.BridgeConfirm);
    }
    function handleConfirmation() {
      handleTransferTradingAccountTrack();
      handlerFunction.value();
    }
    function handleWithdrawToInjective() {
      status.setLoading();
      bankStore.transfer({
        amount: new BigNumberInBase(formValues.value[BridgeField.Amount]),
        denom: formValues.value[BridgeField.Token].denom,
        destination: formValues.value[BridgeField.Destination],
        memo: formValues.value[BridgeField.Memo],
        token: formValues.value[BridgeField.Token]
      }).then(() => {
        success({ title: t("bridge.withdrawToInjectiveAddressSuccess") });
        emit("form:submit");
        emitFundingRefresh();
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function handleTransferToTradingAccount() {
      status.setLoading();
      bankStore.deposit({
        amount: new BigNumberInBase(formValues.value[BridgeField.Amount]),
        token: formValues.value[BridgeField.Token]
      }).then(() => {
        success({ title: t("bridge.depositToTradingAccountSuccess") });
        emit("form:submit");
        emitFundingRefresh();
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function handleWithdraw() {
      status.setLoading();
      if (ethBridgeFee.value.gte(formValues.value[BridgeField.Amount])) {
        return;
      }
      peggyStore.withdraw({
        bridgeFee: ethBridgeFee.value,
        token: formValues.value[BridgeField.Token],
        amount: new BigNumberInBase(formValues.value[BridgeField.Amount])
      }).then(() => {
        success({ title: t("bridge.withdrawFromInjectiveSuccess") });
        emit("form:submit");
        emitFundingRefresh();
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function handleDeposit() {
      status.setLoading();
      peggyStore.transfer({
        amount: new BigNumberInBase(formValues.value[BridgeField.Amount]),
        token: formValues.value[BridgeField.Token]
      }).then(() => {
        success({ title: t("bridge.depositToInjectiveSuccess") });
        emit("form:submit");
        emitFundingRefresh();
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function handleTransferToBank() {
      status.setLoading();
      bankStore.withdraw({
        amount: new BigNumberInBase(formValues.value[BridgeField.Amount]),
        token: formValues.value[BridgeField.Token]
      }).then(() => {
        success({ title: t("bridge.withdrawFromTradingAccountSuccess") });
        emit("form:submit");
        emitFundingRefresh();
      }).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    function handleTransferTradingAccountTrack() {
      amplitudeTracker.transferTradingAccountTrack({
        transferDirection: formValues.value[BridgeField.TransferDirection],
        token: formValues.value[BridgeField.Token].name,
        amount: formValues.value[BridgeField.Amount]
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_BaseIcon = __nuxt_component_1;
      const _component_ModalsBridgeNetworkCard = __nuxt_component_2;
      const _component_ModalsBridgeConfirmRow = __nuxt_component_3;
      const _component_AppButton = __nuxt_component_4;
      const _component_ModalsBridgeNotSupportedBridgeTypeNote = __nuxt_component_5;
      const _component_CommonUserNotConnectedNote = __nuxt_component_6;
      const _component_AppModal = __nuxt_component_7;
      return _openBlock(), _createBlock(_component_AppModal, {
        show: _unref(isModalOpen),
        sm: "",
        "data-cy": "transfer-confirm-modal",
        "onModal:closed": handleModalClose
      }, {
        title: _withCtx(() => [
          _createElementVNode("h3", null, [
            _unref(isDeposit) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("bridge.depositToInjective")), 1)) : _unref(isWithdraw) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("bridge.withdrawFromInjective")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("bridge.transferFromToTradingAccount")), 1))
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _unref(walletStore).isUserWalletConnected ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              !_unref(networkIsNotSupported) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("bridge.confirmTransaction")), 1),
                _unref(formValues)[_unref(BridgeField).Token] ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _unref(formValues)[_unref(BridgeField).Token].logo ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                    key: 0,
                    token: _unref(formValues)[_unref(BridgeField).Token],
                    xl: "",
                    class: "mx-auto"
                  }, null, 8, ["token"])) : (_openBlock(), _createBlock(_component_BaseIcon, {
                    key: 1,
                    name: "category-alt",
                    class: "text-gray-200 rounded-full w-10 h-10 mx-auto"
                  })),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(amountToString)) + " " + _toDisplayString(_unref(formValues)[_unref(BridgeField).Token].symbol), 1),
                  _unref(amountInUsd).gt(0) ? (_openBlock(), _createElementBlock("p", _hoisted_9, " $" + _toDisplayString(_unref(amountInUsdToString)), 1)) : _createCommentVNode("", true)
                ])) : _createCommentVNode("", true),
                _unref(originNetworkMeta) && _unref(destinationNetworkMeta) ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_ModalsBridgeNetworkCard, {
                    class: "w-1/2",
                    "data-cy": "transfer-confirm-modal-from-text-content",
                    "hide-icon": _unref(originNetworkMeta).value === _unref(destinationNetworkMeta).value,
                    "network-meta": _unref(originNetworkMeta)
                  }, null, 8, ["hide-icon", "network-meta"]),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_BaseIcon, {
                      name: "arrow",
                      class: "text-gray-1000 w-4 h-4 rotate-180"
                    })
                  ]),
                  _createVNode(_component_ModalsBridgeNetworkCard, {
                    class: "w-1/2",
                    "data-cy": "transfer-confirm-modal-to-text-content",
                    "hide-icon": _unref(originNetworkMeta).value === _unref(destinationNetworkMeta).value,
                    "network-meta": _unref(destinationNetworkMeta)
                  }, null, 8, ["hide-icon", "network-meta"])
                ])) : _createCommentVNode("", true),
                _unref(originIsInjective) ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_ModalsBridgeConfirmRow, { class: "mb-4" }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("bridge.amount")), 1)
                    ]),
                    amount: _withCtx(() => [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(amountToString)) + " " + _toDisplayString(_unref(formValues)[_unref(BridgeField).Token].symbol), 1)
                    ]),
                    amountInUsd: _withCtx(() => [
                      _createElementVNode("span", _hoisted_14, " $" + _toDisplayString(_unref(amountInUsdToString)), 1)
                    ]),
                    _: 1
                  }),
                  _unref(destinationIsEthereum) ? (_openBlock(), _createBlock(_component_ModalsBridgeConfirmRow, {
                    key: 0,
                    class: "mb-4"
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("bridge.bridgeFee")), 1)
                    ]),
                    amount: _withCtx(() => [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(ethBridgeFeeToString)) + " " + _toDisplayString(_unref(formValues)[_unref(BridgeField).Token].symbol), 1)
                    ]),
                    amountInUsd: _withCtx(() => [
                      _createElementVNode("span", _hoisted_16, " $" + _toDisplayString(_unref(ethBridgeFeeInUsdToString)), 1)
                    ]),
                    _: 1
                  })) : _createCommentVNode("", true)
                ])) : _createCommentVNode("", true),
                _unref(originIsInjective) ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_ModalsBridgeConfirmRow, {
                    class: "mb-4",
                    bold: ""
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("bridge.transferAmount")), 1)
                    ]),
                    amount: _withCtx(() => [
                      _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(transferAmountToString)) + " " + _toDisplayString(_unref(formValues)[_unref(BridgeField).Token].symbol), 1)
                    ]),
                    amountInUsd: _withCtx(() => [
                      _createElementVNode("span", _hoisted_19, " $" + _toDisplayString(_unref(transferAmountInUsdToString)), 1)
                    ]),
                    _: 1
                  }),
                  false ? (_openBlock(), _createBlock(_component_ModalsBridgeConfirmRow, {
                    key: 0,
                    bold: "",
                    class: "mb-6"
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("bridge.gasFee")), 1)
                    ]),
                    amount: _withCtx(() => [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(gasFeeToString)) + " " + _toDisplayString(_unref(injToken).symbol), 1)
                    ]),
                    amountInUsd: _withCtx(() => [
                      _createElementVNode("span", _hoisted_21, " $" + _toDisplayString(_unref(gasFeeInUsdToString)), 1)
                    ]),
                    _: 1
                  })) : (_openBlock(), _createBlock(_component_ModalsBridgeConfirmRow, {
                    key: 1,
                    bold: "",
                    class: "mb-6"
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("bridge.gasFee")), 1)
                    ]),
                    amount: _withCtx(() => [
                      _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("common.waived")), 1)
                    ]),
                    _: 1
                  }))
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_AppButton, {
                    lg: "",
                    class: "w-full font-semibold rounded bg-blue-500 text-blue-900",
                    disabled: _unref(isConfirmationDisabled),
                    status: _unref(status),
                    "data-cy": "transfer-confirm-modal-confirm-button",
                    onClick: handleConfirmation
                  }, {
                    default: _withCtx(() => [
                      _unref(originIsInjective) && !_unref(bankStore).hasEnoughInjForGas ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.$t("bridge.insufficientINJForGas")), 1)) : _createCommentVNode("", true),
                      _unref(transferAmount).lte(0) ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.$t("bridge.insufficientAmount")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.$t("bridge.confirm")), 1))
                    ]),
                    _: 1
                  }, 8, ["disabled", "status"])
                ])
              ])) : (_openBlock(), _createBlock(_component_ModalsBridgeNotSupportedBridgeTypeNote, _normalizeProps(_mergeProps({ key: 1 }, {
                selectedNetwork: _unref(formValues)[_unref(BridgeField).BridgingNetwork]
              })), null, 16))
            ])) : (_openBlock(), _createBlock(_component_CommonUserNotConnectedNote, { key: 1 }))
          ])
        ]),
        _: 1
      }, 8, ["show"]);
    };
  }
});
